import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetWorkorderByIdQuery,
  useSendEmailReadyForPickupMutation,
  useSendEmailReceivedAtWarehouseMutation,
} from "../../../../app/services/workOrderSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDialogModel } from "../../../../hooks";
import SingleWorkOrder from "./SingleWorkOrder";

const ViewWorkOrder = () => {
  const { id } = useParams();
  const [open, openDialog, closeDialog] = useDialogModel();
  const [emailDialogReadyForPickup, setEmailDialogReadyForPickup] =
    useState(false);
  const [emailDialogReceived, setEmailDialogReceived] = useState(false);
  const [invoiceDialog, setInoviceDialog] = useState(false);

  const { state } = useLocation();
  window.history.replaceState({}, document.title);
  const { data, isLoading, error, isFetching, isSuccess, refetch } =
    useGetWorkorderByIdQuery(id, {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    });
  const [sendEmailRequestReadyForPickup, emailRequestQueryReadyForPickup] =
    useSendEmailReadyForPickupMutation();
  const [sendEmailRequestReceived, emailRequestQueryReceived] =
    useSendEmailReceivedAtWarehouseMutation();
  // console.log({ data });
  const navigate = useNavigate();

  const onEmailSentPickup = async () => {
    const isShippedAndCheckedIn =
      data?.status === "shipped" &&
      data?.cargoAvailableStatus === "checkInComplete";
    const isEmailSent = data?.is_email_sended_ready_for_pickup;
    if (isEmailSent) {
      return;
    }
    if (!isShippedAndCheckedIn) {
      toast.error(
        "Failed to send email: Order status is not 'Shipped' , 'Ready for pickup'",
        {
          containerId: "error",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
      closeDialog();
      setEmailDialogReadyForPickup(false);
      return;
    }
    try {
      const response = await sendEmailRequestReadyForPickup({
        id: data?._id,
      }).unwrap();
      // console.log({ response });
      if (response?.success) {
        closeDialog();
        setEmailDialogReadyForPickup(false);
        navigate(`/dashboard/work-orders/view/${data?._id}`, {
          state: response,
        });
        // refetch();
        // toast.success(response.message);
      }
    } catch (error) {
      // console.log({ error });
      toast.error(error?.data?.message, {
        containerId: "error",
        closeOnClick: true,
        toastId: 12,
        autoClose: 1000,
      });
    }
  };
  const onEmailSentReceived = async () => {
    const isShippedAndReceived =
      data?.status === "shipped" &&
      data?.cargoAvailableStatus === "receivedAtWarehouse";
    const isEmailSent = data?.is_email_sended_received;
    if (isEmailSent) {
      return;
    }
    if (!isShippedAndReceived) {
      toast.error(
        "Failed to send email: Order status is not 'Shipped' , 'Received at warehouse'",
        {
          containerId: "error",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
      closeDialog();
      setEmailDialogReceived(false);
      return;
    }
    try {
      const response = await sendEmailRequestReceived({
        id: data?._id,
      }).unwrap();
      // console.log({ response });
      if (response?.success) {
        closeDialog();
        setEmailDialogReadyForPickup(false);
        navigate(`/dashboard/work-orders/view/${data?._id}`, {
          state: response,
        });
        // refetch();
        // toast.success(response.message);
      }
    } catch (error) {
      // console.log({ error });
      toast.error(error?.data?.message, {
        containerId: "error",
        closeOnClick: true,
        toastId: 12,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    if (state?.success) {
      console.log({ state });
      // console.log("creating work order")
      // toast.success(state.message);
      toast.success(state.message, {
        containerId: "successToast",
        closeOnClick: true,
        toastId: 122,
        autoClose: 1000,
      });
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate("/login", { state: { expired: true } });
      }
    }
  }, [error, navigate]);

  return (
    <div>
      <ToastContainer containerId={"parentToast"} />
      <ToastContainer containerId={"successToast"} />
      <ToastContainer containerId={"error"} />
      <ToastContainer autoClose={1000} />
      <SingleWorkOrder
        closeDialog={closeDialog}
        open={open}
        data={data}
        isFetching={isFetching}
        isSuccess={isSuccess}
        id={id}
        isLoading={isLoading}
        invoiceDialog={invoiceDialog}
        onEmailSentPickup={onEmailSentPickup}
        refetch={refetch}
        setEmailDialogReceived={setEmailDialogReceived}
        emailDialogReceived={emailDialogReceived}
        emailDialogReadyForPickup={emailDialogReadyForPickup}
        emailRequestQueryReadyForPickup={emailRequestQueryReadyForPickup}
        openDialog={openDialog}
        setEmailDialogReadyForPickup={setEmailDialogReadyForPickup}
        setInoviceDialog={setInoviceDialog}
        key={data?._id}
        onEmailSentReceived={onEmailSentReceived}
        emailRequestQueryReceived={emailRequestQueryReceived}
      />
    </div>
  );
};

export default ViewWorkOrder;
