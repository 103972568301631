import React, { useState, useTransition } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import Logo from "../../../../assets/images/rbmlogo.png";
import { DateFormat } from "../../../../utils/format";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Loader } from "../../../common/loader";
const GenerateInvoice = ({ data }) => {
  const [loading, setLoading] = useState(false); // Step 1: Loading state

  const onHandleDownloadInvoice = async () => {
    setLoading(true); // Start loader
    // console.log("Donwload invoice");

    const invoiceDiv = document.getElementById("invoiceCapture");
    // Disable scrolling
    invoiceDiv.style.overflow = "hidden";
    invoiceDiv.style.height = "auto";
    // openDialog();
    try {
      const canvas = await html2canvas(invoiceDiv, { scale: 3 });
      const imgData = canvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: [canvas.width, canvas.height],
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(
        `INVOICE-${data?.title.replace(/\s+/g, "")}-${data?.trackingId}.pdf`
      );
    } catch (error) {
      console.error("Error generating invoice:", error);
    } finally {
      invoiceDiv.style.overflow = "auto";
      invoiceDiv.style.height = "30em";
      setLoading(false); // Stop loader
    }
  };

  const currentDate = new Date().toLocaleDateString();

  // console.log({ packages: data?.packages });
  return (
    <div className=" w-[80%] m-auto mt-10 flex gap-4 flex-col">
      <div
        className="min-h-[30em] h-[30em] overflow-scroll flex flex-col gap-14 px-16 py-10  border-2 border-gray-200 rounded-md"
        id="invoiceCapture"
      >
        {loading ? (
          <div className="flex items-center justify-center">
            <h1>Downloading PDF, please wait...</h1>
          </div>
        ) : (
          <>
            <div className="flex flex-row  justify-between relative">
              <div className="flex flex-col text-justify text-blue-500 font-semibold text-[0.86rem]">
                <h1 className="text-xl font-bold">RBM TRADING LLC</h1>
                <h4>146-19 228 TH STREET</h4>
                <h4>SPRINGFIELD GARDENS, NY 11413 </h4>
                <h4>TEL # 917-660-6872</h4>
                <h4 className="mt-2">LETS SPIN THE WORLD ON YOUR FINGER TIP</h4>
              </div>
              <div className="">
                {" "}
                <img className="w-[5em]" src={Logo} alt="logo" />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="text-[1rem] font-bold  tracking-[0.07em]">
                <h3>Bill to</h3>
                <h3>Customer : {data.title}</h3>
                <h3>Tracking ID : {data.trackingId}</h3>
              </div>
              <div className="text-[1rem] font-bold  tracking-wider">
                <h4>Invoice Date : {currentDate}</h4>
              </div>
            </div>
            {data?.packages?.length && (
              <div className="text-center font-bold  tracking-wider text-xl">
                Packages
              </div>
            )}
            {data?.packages?.length > 0 && (
              <div className="flex flex-col gap-1 ">
                <div className="flex flex-row border-b-2 border-gray-300 rounded-md py-1">
                  <div className="text-center flex-[30%] py-1 font-bold border-r-2 ">
                    Title
                  </div>
                  <div className=" flex-[10%] text-center py-1 font-bold border-r-2">
                    Type
                  </div>
                  <div className=" flex-[10%] text-center py-1 font-bold border-r-2">
                    Weight
                  </div>
                  <div className=" flex-[10%] text-center py-1 font-bold border-r-2">
                    Cost
                  </div>

                  <div className=" flex-[10%] text-center py-1 font-bold">
                    Location
                  </div>
                </div>
                {data?.packages.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b-2 border-gray-300 flex flex-row py-2"
                    >
                      <div className="flex-[30%] text-center py-1 border-r-2">
                        {item?.title}
                      </div>
                      <div className="flex-[10%] text-center py-1 border-r-2 capitalize">
                        {item?.type}
                      </div>
                      <div className="flex-[10%] text-center py-1 border-r-2">
                        {item?.weight && `${item?.weight} LB`}
                      </div>
                      <div className="flex-[10%] text-center py-1 border-r-2">
                        {item?.cost && `${item?.cost} USD`}
                      </div>
                      <div className="flex-[10%] text-center py-1">
                        {item?.location}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="flex flex-col gap-4  ">
              <div className="flex justify-between">
                <div className="flex flex-col font-medium gap-1 w-[40%]">
                  <div className="flex justify-between">
                    <p>Cargo Status</p>
                    <p>
                      {data?.cargoAvailableStatus === "inProcessOfCheckIn"
                        ? "In Process Of Check In"
                        : data?.cargoAvailableStatus === "receivedAtWarehouse"
                        ? "Received At Warehouse"
                        : "Ready For Pick Up"}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p>Final Status</p>
                    <p>
                      {data?.status === "placed"
                        ? "Placed"
                        : data?.status === "shipped"
                        ? "Shipped"
                        : "Delivered"}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p>Arrival Date</p>
                    <p>{DateFormat(data?.arrivalDate, true)}</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Departure Date</p>
                    <p>{DateFormat(data?.departureDate, true)}</p>
                  </div>
                  <div className="flex justify-between">
                    <p>Last Free Day Date</p>
                    <p>{DateFormat(data?.last_free_day, true)}</p>
                  </div>
                </div>
                <div className="flex flex-col font-medium gap-4 w-[35%]">
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between">
                      <p>Total Weight</p>
                      <p>{`${data?.total_weight} Lbs`}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p>Import Fee </p> <p>{`${data?.import_fee} USD`}</p>
                    </div>
                    {/* <hr className="my-1 h-[0.05em]  bg-slate-100   " /> */}
                    <div className="flex flex-row justify-between">
                      <p>Handling Fee </p> <p>{`${data?.handling_fee} USD`}</p>
                    </div>
                    {/* <hr className="my-1 h-[0.09em]  bg-slate-200   " /> */}
                    <div className="flex flex-row justify-between">
                      <p>Duty Fee </p> <p>{`${data?.duty} USD`}</p>
                    </div>
                    {data?.miscellaneous_fees_one && (
                      <div className="flex flex-row justify-between">
                        <p>Miscellaneous Fees 1 </p>{" "}
                        <p>{`${data?.miscellaneous_fees_one} USD`}</p>
                      </div>
                    )}
                    {data?.miscellaneous_fees_two && (
                      <div className="flex flex-row justify-between">
                        <p>Miscellaneous Fees 2 </p>{" "}
                        <p>{`${data?.miscellaneous_fees_two} USD`}</p>
                      </div>
                    )}
                    {data?.miscellaneous_fees_three && (
                      <div className="flex flex-row justify-between">
                        <p>Miscellaneous Fees 3 </p>{" "}
                        <p>{`${data?.miscellaneous_fees_three} USD`}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <hr className="my-3 h-[0.09em]  bg-slate-500   " />
                    <div className="flex flex-row justify-between">
                      <p>Total Cost </p> <p>{`${data?.total_cost} USD`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col tracking-wider font-semibold border-t-2 border-b-2  pt-5 pb-5 text-sm items-start ">
                <div className="text-blue-600">
                  <p>AFTER EXPIRE $100 GYD PER LB DAILY</p>
                  <p>CURRENT EXCHANGE RATE $222 GYD - 1 USD</p>
                </div>
              </div>
              <div className="flex flex-col mt-1 font-semibold text-sm tracking-wider">
                <p className=" text-lg tracking-normal">PICK UP ADDRESS</p>
                <div className="mt-2 ">
                  <p className="">43 RAILWAY LINE KITTY </p>
                  <p>CAMPELL-VILLE, GEORGETOWN</p>
                  <p>TEL # 592-627-9369</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <button
        onClick={onHandleDownloadInvoice}
        className={`self-end font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white  bg-blue-500 hover:bg-blue-600`}
      >
        <MdOutlineFileDownload size={18} />
        Download Invoice
      </button>
    </div>
  );
};

export default GenerateInvoice;
