import * as Yup from "yup";

export const Model = {
  formField: {
    status: {
      name: "status",
      label: "Status",
      placeholder: "Select Status",
    },
    cargoAvailableStatus: {
      name: "cargoAvailableStatus",
      label: "Cargo Available Status",
      placeholder: "Select Cargo Available Status",
    },
    departureDate: {
      name: "departureDate",
      label: "Departure Date",
    },
    arrivalDate: {
      name: "arrivalDate",
      label: "Arrival Date",
    },
    lastFreeDay: {
      name: "last_free_day",
      label: "Last Free Day",
    },
  },
};

const {
  formField: {
    status,
    cargoAvailableStatus,
    arrivalDate,
    departureDate,
    lastFreeDay,
  },
} = Model;

export const initialValues = {
  [status.name]: "",
  [cargoAvailableStatus.name]: "",
  [arrivalDate.name]: null,
  [departureDate.name]: null,
  [lastFreeDay.name]: null,
};

export const validationSchema = Yup.object({
  [status.name]: Yup.string(),
  [cargoAvailableStatus.name]: Yup.string(),
  [arrivalDate.name]: Yup.date().nullable().notRequired(),
  [departureDate.name]: Yup.date().nullable().notRequired(),
  [lastFreeDay.name]: Yup.date().nullable().notRequired(),
});
