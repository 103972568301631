import React, { useEffect, useState } from "react";
import { Model } from "../../../../../formModel/fee";
import { useFormikContext } from "formik";
import {
  Checkbox,
  IconTextButton,
  InputFieldWithLabel,
} from "../../../../common/formElements";

const FeeForm = ({
  packageType,
  totalWeight,
  mscFeeCounter,
  setMscFeeCounter,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    formField: {
      duty,
      handlingFee,
      miscellaneousFeesOne,
      miscellaneousFeesOneNote,
      miscellaneousFeesThreeNote,
      miscellaneousFeesTwoNote,
      miscellaneousFeesThree,
      miscellaneousFeesTwo,
      importFee,
      wantToUseCalculator,
    },
  } = Model;
  // console.log({ totalWeight })
  // console.log({ values })

  const onAddMiscellaneousFeeBtnClick = (e) => {
    e.preventDefault();
    setMscFeeCounter((p) => p + 1);
  };
  const onRemoveMiscellaneousFeeBtnClick = (e) => {
    e.preventDefault();
    setMscFeeCounter((p) => p - 1);
  };

  useEffect(() => {
    if (values.wantToUseCalculator && packageType === "consolidation") {
      let importFeeAndHandlingCost = "";
      if (totalWeight > 0 && totalWeight < 21) {
        importFeeAndHandlingCost = totalWeight * 0.5;
      } else if (totalWeight >= 21 && totalWeight < 41) {
        importFeeAndHandlingCost = totalWeight * 0.45;
      } else if (totalWeight >= 41 && totalWeight < 71) {
        importFeeAndHandlingCost = totalWeight * 0.35;
      } else if (totalWeight >= 71) {
        importFeeAndHandlingCost = totalWeight * 0.25;
      }

      setFieldValue(
        importFee.name,
        (importFeeAndHandlingCost / 2).toFixed(5).toString()
      );
      setFieldValue(
        handlingFee.name,
        (importFeeAndHandlingCost / 2).toFixed(5).toString()
      );
    }
    if (packageType === "express") {
      setFieldValue(importFee.name, "0");
      setFieldValue(handlingFee.name, "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.wantToUseCalculator, totalWeight]);

  useEffect(() => {
    if (values.miscellaneous_fees_one || values.miscellaneous_fees_one_note) {
      setMscFeeCounter(1);
    }
    if (values.miscellaneous_fees_two || values.miscellaneous_fees_two_note) {
      setMscFeeCounter(2);
    }
    if (
      values.miscellaneous_fees_three ||
      values.miscellaneous_fees_three_note
    ) {
      setMscFeeCounter(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-row gap-3">
        <Checkbox
          name={wantToUseCalculator.name}
          fieldlabel={`${wantToUseCalculator.label} (${packageType})`}
          desc={`${wantToUseCalculator.desc} (${packageType})`}
        />
      </div>
      {((!values.wantToUseCalculator && packageType === "express") ||
        packageType === "consolidation") && (
        <div className="flex flex-row gap-3">
          <InputFieldWithLabel
            fieldlabel={importFee.label}
            placeholder={importFee.label}
            name={importFee.name}
            disabled={values.wantToUseCalculator}
            priceField={true}
            onChange={(event) =>
              setFieldValue(
                importFee.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
          <InputFieldWithLabel
            fieldlabel={handlingFee.label}
            placeholder={handlingFee.label}
            name={handlingFee.name}
            disabled={values.wantToUseCalculator}
            priceField={true}
            onChange={(event) =>
              setFieldValue(
                handlingFee.name,
                event.target.value.replace(/[^0-9.]/g, "")
              )
            }
          />
        </div>
      )}
      <div className="flex flex-row gap-3">
        <InputFieldWithLabel
          fieldlabel={duty.label}
          placeholder={duty.label}
          name={duty.name}
          priceField={true}
          onChange={(event) =>
            setFieldValue(duty.name, event.target.value.replace(/[^0-9.]/g, ""))
          }
        />
      </div>
      <div className="flex flex-row gap-3">
        <IconTextButton
          type={"add"}
          title={"Add Miscellaneous Fees"}
          onClick={onAddMiscellaneousFeeBtnClick}
        />
        {!(mscFeeCounter === 0) && (
          <IconTextButton
            type={"remove"}
            title={"Remove Miscellaneous Fees"}
            onClick={onRemoveMiscellaneousFeeBtnClick}
          />
        )}
      </div>
      {mscFeeCounter > 0 && (
        <div className="flex flex-row gap-3">
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesOne.label}
            placeholder={miscellaneousFeesOne.label}
            name={miscellaneousFeesOne.name}
            priceField={true}
            // onChange={(event) =>
            //   setFieldValue(
            //     miscellaneousFeesOne.name,
            //     event.target.value.replace(/[^0-9.-]/g, "")
            //   )
            // }
            onChange={(event) => {
              const value = event.target.value;

              // Regex to allow optional minus sign at the start, followed by numbers and an optional single decimal point
              const formattedValue = value
                .replace(/[^0-9.-]/g, "") // Remove invalid characters
                .replace(/(?!^)-/g, "") // Remove any minus signs that are not at the start
                .replace(/^(-?\d*\.?\d*).*$/, "$1"); // Allow only one decimal point

              setFieldValue(miscellaneousFeesOne.name, formattedValue);
            }}
          />
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesOneNote.label}
            placeholder={miscellaneousFeesOneNote.label}
            name={miscellaneousFeesOneNote.name}
            // onChange={(event) =>
            //   setFieldValue(
            //     miscellaneousFeesOne.name,
            //     event.target.value.replace(/[^0-9.]/g, "")
            //   )
            // }
          />
        </div>
      )}

      {mscFeeCounter > 1 && (
        <div className="flex flex-row gap-3">
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesTwo.label}
            placeholder={miscellaneousFeesTwo.label}
            name={miscellaneousFeesTwo.name}
            priceField={true}
            // onChange={(event) =>
            //   setFieldValue(
            //     miscellaneousFeesTwo.name,
            //     event.target.value.replace(/[^0-9.-]/g, "")
            //   )
            // }
            onChange={(event) => {
              const value = event.target.value;

              // Regex to allow optional minus sign at the start, followed by numbers and an optional single decimal point
              const formattedValue = value
                .replace(/[^0-9.-]/g, "") // Remove invalid characters
                .replace(/(?!^)-/g, "") // Remove any minus signs that are not at the start
                .replace(/^(-?\d*\.?\d*).*$/, "$1"); // Allow only one decimal point
              setFieldValue(miscellaneousFeesTwo.name, formattedValue);
            }}
          />
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesTwoNote.label}
            placeholder={miscellaneousFeesTwoNote.label}
            name={miscellaneousFeesTwoNote.name}
          />
        </div>
      )}
      {mscFeeCounter > 2 && (
        <div className="flex flex-row gap-3">
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesThree.label}
            placeholder={miscellaneousFeesThree.label}
            name={miscellaneousFeesThree.name}
            priceField={true}
            // onChange={(event) =>
            //   setFieldValue(
            //     miscellaneousFeesThree.name,
            //     event.target.value.replace(/[^0-9.-]/g, "")
            //   )
            // }
            onChange={(event) => {
              const value = event.target.value;

              // Regex to allow optional minus sign at the start, followed by numbers and an optional single decimal point
              const formattedValue = value
                .replace(/[^0-9.-]/g, "") // Remove invalid characters
                .replace(/(?!^)-/g, "") // Remove any minus signs that are not at the start
                .replace(/^(-?\d*\.?\d*).*$/, "$1"); // Allow only one decimal point
              setFieldValue(miscellaneousFeesThree.name, formattedValue);
            }}
          />
          <InputFieldWithLabel
            fieldlabel={miscellaneousFeesThreeNote.label}
            placeholder={miscellaneousFeesThreeNote.label}
            name={miscellaneousFeesThreeNote.name}
          />
        </div>
      )}
    </>
  );
};

export default FeeForm;
