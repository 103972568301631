import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import Label from "./Label";
import ErrorMessage from "./ErrorMessage";

const Datepicker = ({ fieldlabel, ...props }) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);

  const onDateChange = (date) => {
    setValue(date);
  };
 
  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <Label label={fieldlabel} />
      <div className="flex flex-col w-full">
        <DatePicker
          placeholderText="Click to select a date"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block w-full p-[0.52em] font-semibold text-[1rem] focus:outline-gray-800"
          selected={value}
          onChange={onDateChange}
        />
        <ErrorMessage error={error} touched={touched} />
      </div>
    </div>
  );
};

export default Datepicker;
