import React from "react";
import _ from "lodash";
import { TableButton } from "../formElements";
import { DateFormat, calculateTotalWeight } from "../../../utils/format";

const TableData = ({
  onEditItem,
  onDeleteItem,
  onViewItem,
  showEditButton,
  showDeleteButton,
  showViewButton,
  data,
  itemNumber,
  column,
  index,
}) => {
  let content;
  if (column?.name === "action") {
    content = (
      <td
        className={`${column.styles} flex-1 justify-center flex gap-1 items-center `}
      >
        {showViewButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onViewItem(data);
            }}
            type={"view"}
          />
        )}
        {showEditButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onEditItem(data);
            }}
            type={"edit"}
          />
        )}
        {showDeleteButton && (
          <TableButton
            onClick={(e) => {
              e.stopPropagation();
              onDeleteItem(data);
            }}
            type={"delete"}
          />
        )}
      </td>
    );
  } else if (column.name === "index") {
    content = <td className={column.styles}>{itemNumber}</td>;
  } else if (column.date) {
    content = (
      <td
        className={`${column.styles} flex-1 justify-center flex py-1 items-center`}
      >
        {_.get(data, column.key)
          ? DateFormat(_.get(data, column.key), false)
          : ""}
      </td>
    );
  } else if (
    column.key === "cargoAvailableStatus" ||
    column.cargoAvailableStatus
  ) {
    // receivedAtWarehouse
    content = (
      <td
        className={`${column.styles} flex-1 justify-center flex py-1 items-center break-words text-wrap text-center`}
      >
        {_.get(data, column.key) === "inProcessOfCheckIn" ? (
          <p className="bg-slate-200 text-slate-700 px-2 rounded-md">
            In Process Of Check In
          </p>
        ) : _.get(data, column.key) === "receivedAtWarehouse" ? (
          <p className="bg-green-200 text-green-700 px-2 rounded-md">
            Received At Warehouse
          </p>
        ) : (
          <p className="bg-blue-200 text-blue-700 px-2 rounded-md">
            Ready for Pick up
          </p>
        )}
      </td>
    );
  } else if (column.cost) {
    content = (
      <td
        className={`${column.styles} capitalize flex-1 justify-center flex py-1 items-center`}
      >
        {`${_.get(data, column.key)} ${_.get(data, column.key) && "usd"}`}
      </td>
    );
  } else if (column.key === "status" || column.status) {
    content = (
      <td
        className={`${column.styles} capitalize flex-1 justify-center flex py-1 items-center break-words text-wrap text-center`}
      >
        {_.get(data, column.key) === "placed" ? (
          <p className="bg-orange-200 text-orange-700 px-2 rounded-md">
            Placed
          </p>
        ) : _.get(data, column.key) === "shipped" ? (
          <p className="bg-emerald-200 text-emerald-700 px-2 rounded-md">
            Shipped
          </p>
        ) : (
          <p className="bg-blue-200 text-blue-700 px-2 rounded-md">Delivered</p>
        )}
      </td>
    );
  } else if (column.bool_value) {
    content = (
      <td
        className={`${column.styles} capitalize flex-1 justify-center flex py-1 items-center `}
      >
        {_.get(data, column.key) ? (
          <p className="bg-blue-200 text-blue-700 px-2 rounded-md">yes</p>
        ) : (
          <p className="bg-slate-100 text-slate-700 px-2 rounded-md">No</p>
        )}
      </td>
    );
  } else if (column.weight) {
    content = (
      <td
        className={`${column.styles} capitalize flex-1 justify-center flex py-1 items-center break-words text-wrap text-center`}
      >
        {calculateTotalWeight(_.get(data, column.key))}
      </td>
    );
  } else {
    content = (
      <td
        className={`${column.styles}  break-words text-wrap text-center   flex-wrap capitalize flex-1 justify-center flex py-1 items-center`}
      >
        {column.key === "custom_cost_per_pound"
          ? Number(_.get(data, column.key)) > 0
            ? _.get(data, "want_to_use_calculator")
              ? _.get(data, column.key)
              : ""
            : _.get(data, "cost_per_pound")
          : _.get(data, column.key)}
      </td>
    );
  }
  return content;
};

export default TableData;
