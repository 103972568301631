import React, { useState } from "react";
import Dialog from "./Dialog";
import { BackDropLoader } from "../loader";


const EmailDialogReceived = ({
  open,
  handleClose,
  onSubmitForm,
  isLoading,
}) => {
  return (
    <Dialog small={true} isOpen={open} handleClose={handleClose}>
      {isLoading && <BackDropLoader />}
      <div className="flex h-full w-full flex-col justify-center gap-5">
        <div className=" flex flex-col gap-1 ">
          <p className="text-justify text-sm font-semibold self-center p-2">
            Confirm: Send Email?
          </p>
          <button
            onClick={onSubmitForm}
            className="font-semibold bg-green-500 hover:bg-green-600 text-white py-1 rounded-md"
          >
            Send Email
          </button>
          <button
            className="font-semibold bg-gray-200 py-1 rounded-md hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EmailDialogReceived;
