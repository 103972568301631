export const packagecolumns = [
  {
    title: "Tracking ID",
    styles: "basis-[9%]",
    sorting: false,
    key: "workorder[0].trackingId",
  },
  {
    title: "Pakcage Title",
    styles: "basis-[22%]",
    sorting: false,
    key: "title",
  },
  {
    title: "Workorder title",
    styles: "basis-[10%]",
    sorting: false,
    key: "workorder[0].title",
  },
  // {
  //   title: "Length",
  //   styles: "basis-[7%]",
  //   sorting: false,
  //   key: "length",
  // },

  // {
  //   title: "Width",
  //   styles: "basis-[7%]",
  //   sorting: false,
  //   key: "width",
  // },
  // {
  //   title: "Height",
  //   styles: "basis-[7%]",
  //   sorting: false,
  //   key: "height",
  // },
  {
    title: "Weight (lbs)",
    styles: "basis-[10%]",
    sorting: false,
    key: "weight",
  },
  {
    title: "Cost",
    styles: "basis-[8%]",
    sorting: false,
    key: "cost",
    cost: true,
  },

  {
    title: "Cost Calculator",
    styles: "basis-[8%]",
    sorting: false,
    key: "want_to_use_calculator",
    bool_value: true,
  },
  // {
  //   title: "Cost per pound",
  //   styles: "basis-[8%]",
  //   sorting: false,
  //   key: "custom_cost_per_pound",
  //   cost: true,
  // },
];
