import { apiSlice } from "./apiSlice";

const urlPath = "/packages";

const slice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: ({
        limit,
        page,
        sort,
        type,
        status,
        cargoAvailableStatus,
        debouncedSearchTerm,
        debouncedSearchTermTrackingId,
        searchField,
        favorite,
      }) => ({
        url: (() => {
          let queryString = `${urlPath}?limit=${limit}&page=${page}&sort=${sort}`;
          if (type) {
            queryString += `&type[in]=${type}`;
          }
          if (favorite !== null) {
            console.log({ favinSlice: favorite });
            queryString += `&favourite=${favorite}`;
          }
          if (status) {
            queryString += `&status=${status}`;
          }
          if (cargoAvailableStatus) {
            queryString += `&cargoAvailableStatus=${cargoAvailableStatus}`;
          }
          if (debouncedSearchTerm) {
            queryString += `&search=${debouncedSearchTerm}&searchField=${searchField}`;
          }
          if (debouncedSearchTermTrackingId) {
            queryString += `&search=${debouncedSearchTermTrackingId}&searchField=trackingId`;
          }
          return queryString;
        })(),
      }),
      keepUnusedDataFor: 30,
      transformResponse: (res) => {
        return res.result;
      },
      providesTags: (result, error, arg) => {
        if (result?.data) {
          const tags = [
            { type: "pkg", id: "LIST" },
            ...result.data.map(({ _id }) => ({ type: "pkg", id: _id })),
          ];
          return tags;
        } else {
          return [{ type: "pkg", id: "LIST" }];
        }
      },
    }),
  }),
});

export const { useGetPackagesQuery } = slice;
